import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/configuracion/`


export default class configuracion {
    static async updateShoopEnabled(formData) {
        const respuesta = await axios.post(`${ENDPOINT}updateShoopEnabled`, formData)
        .then(response => {
            return response;
        });
        return respuesta.data;
    }

    static async uploadListPrecios(formDataFile) {
        const formData = new FormData();
        if(formDataFile.file.length > 0) {
            formData.append('file', formDataFile.file[0]);    
        } 
        const respuesta = await axios.post(`${ENDPOINT}uploadListPrecios`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            return response;
        });
        return respuesta.data;
    }
    static async uploadBancoImagenes(formDataFile) {
        const formData = new FormData();
        if(formDataFile.file.length > 0) {
            formData.append('file', formDataFile.file[0]);     
        } 
        const respuesta = await axios.post(`${ENDPOINT}uploadBancoImagenes`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            return response;
        });
        return respuesta.data;
    }
    static async uploadManualMarca(formDataFile) {
        const formData = new FormData();
        if(formDataFile.file.length > 0) {
            formData.append('file', formDataFile.file[0]);    
        } 
        const respuesta = await axios.post(`${ENDPOINT}uploadManualMarca`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            return response;
        });
        return respuesta.data;
    }

    //Terminos y condiciones
    static async updateTerminosCondiciones(formData) {
        const respuesta = await axios.post(`${ENDPOINT}updateTermsConditions`, formData)
        .then(response => {
            return response;
        });
        return respuesta.data;
    }    
    static async getTerminosCondiciones() {
        const respuesta = await axios.get(`${ENDPOINT}getTermsConditions`)
        .then(response => {
            return response;
        });
        return respuesta.data;
    }

    //Politicas reclamos
    static async updatePoliticaReclamos(formData) {
        const respuesta = await axios.post(`${ENDPOINT}updatePoliticaReclamos`, formData)
        .then(response => {
            return response;
        });
        return respuesta.data;
    }  

    static async downloadPoliticaReclamos() {
        try {
            const response = await axios.get(`${ENDPOINT}download/politicaReclamos`, {
                responseType: 'blob', 
            });
            if(response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement("a");
                a.href = url;
                a.download = "_Bases y Condiciones_ - POLITICA de RECLAMOS.pdf";
                document.body.appendChild(a); // Asegura que el elemento esté en el DOM
                a.click();
                document.body.removeChild(a); // Limpia el DOM
                return true;
            }
            else {
                return false;
            }
        } catch (error) {
            return false;
            console.error("Error downloading file:", error);
        }
    }
}

