import axios from 'axios'
import  TokenService  from './token';

const { REACT_APP_ENDPOINT_CUENTA_CORRIENTE } = process.env;
const ENDPOINT_ESTADOPEDIDOS = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/estadopedidos`
const ENDPOINT_SALDOS = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/saldo`
const ENDPOINT_COMPOSICION = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/composicion`
const ENDPOINT_FACTURA = `${REACT_APP_ENDPOINT_CUENTA_CORRIENTE}/api/factura`

export default class cuentacorriente {
    static async getByPresupuestoId(clienteId, presupuestoId) {
        const respuesta = await axios.get(`${ENDPOINT_ESTADOPEDIDOS}/GetByPresupuesto?clienteId=${clienteId}&presupuestoId=${presupuestoId}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async getEstados() {
        const respuesta = await axios.get(`${ENDPOINT_ESTADOPEDIDOS}/GetEstados`)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async getEstadoPedidosByFilter(filter) {
        try {
            const respuesta = await axios.post(`${ENDPOINT_ESTADOPEDIDOS}/GetByFilter`, filter, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                }
            })
            return respuesta.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        } 
    }

    static async getSaldosByFilter(filter) {
        try {
            const respuesta = await axios.post(`${ENDPOINT_SALDOS}/GetByFilter`, filter, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                }
            })
            return respuesta.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        } 
    }

    static async getComposicionByFilter(filter) {
        try {
            const respuesta = await axios.post(`${ENDPOINT_COMPOSICION}/GetByFilter`, filter, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                }
            })
            return respuesta.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        } 
    }

    static async getFacturasByFilter(filter) {
        try {
            const respuesta = await axios.post(`${ENDPOINT_FACTURA}/GetByFilter`, filter, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                }
            })
            return respuesta.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        } 
    }

    static async downloadFileEstadoPedidos(filter) {
        const respuesta = await axios.post(`${ENDPOINT_ESTADOPEDIDOS}/DownloadExcel`, filter,  {
            responseType: 'blob' 
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    static async downloadFileSaldo(filter) {
        const respuesta = await axios.post(`${ENDPOINT_SALDOS}/DownloadExcel`, filter,  {
            responseType: 'blob' 
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    static async downloadFileFactura(filter) {
        const respuesta = await axios.post(`${ENDPOINT_FACTURA}/DownloadExcel`, filter,  {
            responseType: 'blob' 
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    static async downloadFileComposicion(filter) {
        const respuesta = await axios.post(`${ENDPOINT_COMPOSICION}/DownloadExcel`, filter,  {
            responseType: 'blob' 
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }

    static async downloadComprobanteComposicion(cuit, tipo, nroComprobante) {
        const respuesta = await axios.get(`${ENDPOINT_COMPOSICION}/DownloadPdfDrive?cuit=${cuit}&tipo=${tipo}&nroComprobante=${nroComprobante}`, {
            responseType: 'blob' 
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    
    static async downloadComprobanteFactura(cuit, tipo, nroComprobante) {
        const respuesta = await axios.get(`${ENDPOINT_FACTURA}/DownloadPdfDrive?cuit=${cuit}&tipo=${tipo}&nroComprobante=${nroComprobante}`, {
            responseType: 'blob' 
        })
        .then(response => {
            return response
        })  
        return respuesta.data
    }
}