import axios from 'axios';
import  TokenService  from './token';

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Budget/`


export default class PresupuestosService {

    static async downloadFile({numero, comprobante, ruta}) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}Download/${numero}/${comprobante}/${ruta}`,{
                headers: {
                    'Content-Type': 'application/json',
                    authorization: TokenService.getAuthHeader()
                },
                withCredentials: true,
                responseType: 'arraybuffer'
            })
            .then(response => {
                return response
            }) 
            return new Blob([respuesta.data], { type: "application/pdf" })
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async ConfirmarPresupusto(idPresupuesto, observaciones, esPedidoFirme) {
        try {
            const respuesta = await axios.post(`${ENDPOINT}${idPresupuesto}?observaciones=${observaciones}&esPedidoFirme=${esPedidoFirme}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }        
    }

    static async fetchConfirm(data) {
        const respuesta = await axios.get(`${ENDPOINT}FetchPreconfirm/${data.presupuestoId}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async fetchAll(page, clienteId) {
        const respuesta = await axios.get(`${ENDPOINT}fetchAll?page=${page}&clienteId=${clienteId}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async fetch(id) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}fetchAll/${id}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async fetchClientes(id) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}fetchClientes/${id}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async find(id) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}${id}`,{
            headers: {
                authorization: await TokenService.getAuthHeader()
            }
        })
        .then(response => {
            return response
        })  
        return respuesta.data.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async search(search) {
        try {
            const respuesta = await axios.post(`${ENDPOINT}search`, search , {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async fetchLatest(id) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}latest/${id}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async getProductsInShoppingCart(contactoId, clienteId) {
        if(contactoId != null && clienteId != null){
            const respuesta = await axios.get(`${ENDPOINT}${clienteId}/${contactoId}/getProductsInShoppingCart`)
            .then(response => {
                return response
            })  
            return respuesta.data.data;
        }
    }

    static async findCarrito(id, clientId) {
        try {
            if(id != null && clientId != null){
                
                console.log("CARRITO", TokenService.getAuthHeader())
                const respuesta = await axios.get(`${ENDPOINT}${clientId}/${id}/shoppingCart`, {
                        headers: {
                            authorization: TokenService.getAuthHeader(),
                        },
                        withCredentials: true
                });
                return respuesta.data.data;
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
                console.log("CERRANDO SESION CARRITO");
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async getCarrito(id, clientId) {
        try {
            if(id != null && clientId != null){
                const respuesta = await axios.get(`${ENDPOINT}${clientId}/${id}/getShoppingCart`, {
                    headers: {
                        authorization: TokenService.getAuthHeader(),
                    },
                    withCredentials: true
                });
                return respuesta.data.data;
            } 
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async findPreconfirmData(presupuestoId) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}preconfirm/${presupuestoId}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async cancelPresupuesto(presupuestoId) {
        try {
            const respuesta = await axios.get(`${ENDPOINT}cancel/${presupuestoId}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    /* ABM DE PRESUPUESTOS */

    static async addProduct(producto){
        try {
            const respuesta = await axios.post(`${ENDPOINT}shoppingCart`,producto, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async deleteProduct(presupuestoId, item){
        try {
            const respuesta = await axios.delete(`${ENDPOINT}${presupuestoId}/${item}`, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async editProduct(producto, presupuestoId, item){
        try {
            const respuesta = await axios.put(`${ENDPOINT}${presupuestoId}/${item}`, producto, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async editShoppingCart(presupuesto, presupuestoId){
        try {
            const respuesta = await axios.put(`${ENDPOINT}${presupuestoId}`, presupuesto, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data.$values;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }

    static async preconfirmPresupuesto(presupuesto){
        try {
            const respuesta = await axios.put(`${ENDPOINT}preconfirmBudget`, presupuesto, {
                headers: {
                    authorization: TokenService.getAuthHeader(),
                },
                withCredentials: true
            });
            return respuesta.data.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                TokenService.cerrarSession();
            }
            throw error; // Re-lanza el error si no es 401
        }
    }
}