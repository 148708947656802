import React, { useEffect } from "react";
import colores from "../../shared/constants/styles";
import { connect, useDispatch } from "react-redux";
import { updatePoliticaReclamo } from '../../actions/configuracion';
import MyUserService from "../../services/myUser";
import TokenService from "../../services/token";
import DownloadPoliticaReclamo from "../../common/components/politicaReclamos/downloadPoliticaReclamos"

interface PoliticaReclamoProps {
    setOpenDialogPolitica: (openDialog: boolean)=> void;
    successPoliticaReclamos: boolean;
}

const PoliticaReclamo = (props: PoliticaReclamoProps) => {

    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false);
    const handleConfirmarPoliticaReclamo = () => {
        let request = {ClienteId: MyUserService.getMyUser().clienteId, ContactoId: MyUserService.getMyUser().contactoId}
        dispatch(updatePoliticaReclamo(request));
    };
    const handleCancelarPoliticaReclamo = () => {
        TokenService.cerrarSession();
    };

    useEffect(() => {
        if (props.successPoliticaReclamos) {
            MyUserService.setMostrarPolitica(false);
            props.setOpenDialogPolitica(false);
        }
    }, [props.successPoliticaReclamos]);


    return (
        <div className="d-flex flex-wrap">
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    zIndex: "1",
                    backgroundColor: "black",
                    filter: "alpha(opacity=75)",
                    opacity: 0.75,
                }}> 
            </div>
            <div
                className="container"
                style={{
                    position: "fixed",
                    left: "50%",
                    top: "25%",
                    transform: "translate(-50%, -25%)",
                    bottom: "0",
                    zIndex: "2",
                    overflowY: "auto",
                    overflowX: "hidden"
                }}>
                {/* <IconButton
                    aria-label="close"
                    onClick={handleCloseTerminosCondiciones}
                    sx={{
                        position: "absolute",
                        right: 36,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton> */}
                <div className="container" style={{ width: "100%" }}>
                    <div style={{
                        backgroundColor: colores.grisClaro,
                        padding: "4.20rem",
                        borderRadius: "15px 15px 15px 15px",
                    }}>
                        <DownloadPoliticaReclamo checked={checked} setChecked={setChecked}></DownloadPoliticaReclamo>
                        
                        <div style={{ textAlign: "center", margin: "2vw" }}>  
                            <button
                                className={`btn`}
                                disabled={!checked}
                                onClick={handleConfirmarPoliticaReclamo}
                                style={{
                                    width: "12.5rem",
                                    backgroundColor: colores.rojo,
                                    color: "white",
                                }}
                                >
                                ACEPTAR
                            </button>

                            <button
                                className={`btn`}
                                onClick={handleCancelarPoliticaReclamo}
                                style={{
                                    width: "12.5rem",
                                    marginLeft: "1vw",
                                    backgroundColor: colores.grisOscuro,
                                    color: "white",
                                }}
                                >
                                CANCELAR
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default connect
(
    (state: any) => ({
        successPoliticaReclamos: state.configuracion.successPoliticaReclamos
    }),
    dispatch => ({
        updatePoliticaReclamo: (politicaReclamoRequest: any)=> dispatch(updatePoliticaReclamo(politicaReclamoRequest))
    })
)(PoliticaReclamo);
