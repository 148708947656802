import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { requestEliminarPresupuestoDetalle } from '../../../actions/presupuesto';
import ProductoSinImagen from '../../../assets/images/ProductoSinImagen.png';
import { isAllowed } from '../../../shared/utils/permissions';
import DeleteIcon from '@mui/icons-material/Delete';

import './index.css'

const ShopRow = (props) => {

    // const paramsList = MyUserService.getMyUser().paramsList;
    var cantMin = props.data.cantMin ? props.data.cantMin: 0;

    const { data, presupuesto } = props;
    const [count, setCount] = React.useState(data?.cantidadTiras);
    const [state, setState] = React.useState(false);
    // Nuevas funciones para los valores de la tabla de total    
    const redondear = (monto) => {
        return Math.round((monto + Number.EPSILON) * 100) / 100
    }
    const calculoMontoTotalSinDcto = (items) => {
        let totalAmout = 0

        if (items !== undefined) {
            for (let i = 0; i < items.length; i++) {
                totalAmout = (items[i].cantidadTiras * items[i].precio) + totalAmout
            }
        } else {
            return 0
        }
        // Esta formula es para tener dos decimales de forma precisa evitando toFixed que aveces da erroes
        return redondear(totalAmout)
    }   

    useEffect(() => {
        if (isNaN(count))
            setCount(cantMin);
        if (state && isAllowed(14) && isAllowed(16))
            props.handleChange(count, data?.item)
        setState(true)
    }, [count]);

    return (
        <div className='ShoppingCart__items-container'>
            <div className='ShoppingCart__items-1 ShopRow_col'>
                <div style={{ marginRight: '0.5rem' }} className="ShopRow__product-inline"> 
                    <div className="card text-center ShopRow__product-inline" style={{ width: "6rem", marginBlock: "2rem", marginInline: "auto", border: "none", height: '5rem'}}>
                        {data?.producto?.images?.$values[0]
                            ?
                            (<img src={data?.producto?.images?.$values[0]} className="card-img-top" style={{ border: "1px solid black", borderRadius: "7px" }} alt="Imagen del producto" />)
                            :
                            (<img src={ProductoSinImagen} className="card-img-top" style={{ border: "1px solid black", borderRadius: "7px" }} alt="Producto sin imagen" />)
                        }
                    </div>
                </div>
                {/* Producto */}
                <p className="card-title fw-bolder ShopRow__product-inline" style={{ margin: "0", padding: "0", fontSize: "0.9rem", width : "200px" }}>
                    {data?.producto?.descripcion}
                    {data?.dimension1 > 1 ? (
                            <b>- {data?.dimension1?.toFixed(3)} ml  </b>
                    ) : (null)
                    }
                </p>
                <div style={{ margin: "0", padding: "0" }} className="ShopRow__product-inline">
                    {(isAllowed(14) && isAllowed(16)) ? (
                        <p className="fw-bolder btn ShopRow__product-inline" style={{ margin: "0", padding: "0", color: "red", marginRight: '1rem' }}
                            onClick={props.handleClickEliminar}><DeleteIcon fontSize="large" sx={{ color: "red", width: "0.9rem", height: "0.9rem" }}></DeleteIcon></p>
                    ) : (null)
                    }
                </div>
            </div>
            {/* Precio unidad */}
            <div className='ShoppingCart__items-2 ShopRow_col' style={{ paddingTop : "60px"}}>
                <p className="fw-bolder" style={{ margin: "0", padding: "0", fontSize: "0.9rem" }}>
                    <b>{presupuesto?.moneda?.simbolo} {data?.precio?.toFixed(2) } </b>
                </p>
                <div style={{ margin: "0", padding: "0" }}>
                    {data?.actualizadoPrecio ? (
                        <p className="text-success" style={{ fontSize: "0.9rem", paddingLeft: "3px" }}>
                            <b>El precio ha sido actualizado.</b>
                        </p>
                    ) : (null)  
                    }
                </div>
            </div>

            {/* Cantidad */}
            {(isAllowed(14) && isAllowed(16))
                ? <div className='ShoppingCart__items-3 ShopRow_col' style={{ paddingTop : "40px"}}>
                    <div style={{marginTop: "0.7rem"}}>
                        {count > cantMin ?
                            (<span class="minus btn" style={{ color: "gray", fontSize: "1rem" }}
                                onClick={() => setCount(count - 1)}
                            >-</span>)
                            : (<span class="minus btn" style={{ color: "gray", fontSize: "1rem" }}
                            > </span>)
                        }
                        <input
                            type="number text-center"
                            class="count"
                            name="cantidad"
                            id="cantidad"
                            value={count}
                            style={{ width: "4rem", margin: "0", height: "2rem", textAlign: "center", fontSize: "1rem" }}
                            onChange={(e) => setCount(parseInt(e.target.value))}>
                        </input>
                        <span class="plus btn" style={{ color: "gray", fontSize: "1rem" }}
                            onClick={() => setCount(count + 1)}
                        >+</span>
                    </div>
                    {count<= 0 || count < cantMin ? (
                        <div className='text-danger' style={{ fontSize: "1rem", textAlign: "center" }}>La unidad mínima de compra es {cantMin}.</div>
                    ) : null}
                </div>
                : <div className='ShoppingCart__items-3 ShopRow_col' style={{ paddingTop : "60px"}}>
                    <input type="number text-center"
                        class="count"
                        name="cantidad"
                        id="cantidad"
                        Value={count}
                        style={{ width: "7rem", margin: "0", borderRadius: "7px", height: "2.5rem", textAlign: "center", fontSize: "1rem" }}
                        readonly>
                    </input>
                </div>}

            {/* Subtotal sin descuento */}
            <div className='ShoppingCart__items-4 ShopRow_col' style={{ paddingTop : "60px"}}>
                <p className="fw-bolder" style={{ margin: "0", padding: "0", fontSize: "0.9rem" }}><b>{presupuesto?.moneda?.simbolo} {data?.montoSubtotal.toFixed(2)} </b></p>
            </div>
            {/* Descuento Grupo*/}
            <div className='ShoppingCart__items-5 ShopRow_col' style={{ paddingTop : "60px"}}>
                <p className="fw-bolder" style={{ margin: "0", padding: "0", fontSize: "0.9rem" }}>
                    <b>% {data?.descuento1?.toFixed(2)} </b>
                </p>
                <div style={{ margin: "0", padding: "0" }}>
                    {data?.actualizadoDescuento ? (
                        <p className="text-success" style={{ fontSize: "0.9rem", paddingLeft: "3px" }}>
                            <b>El descuento ha sido actualizado.</b>
                        </p>
                    ) : (null)  
                    }
                </div>
            </div>
            {/* Descuento Cliente*/}
            <div className='ShoppingCart__items-6 ShopRow_col' style={{ paddingTop : "60px"}}>
                <p className="fw-bolder" style={{ margin: "0", padding: "0", fontSize: "0.9rem" }}>
                    <b>% {presupuesto.descuentoCliente ? presupuesto.descuentoCliente.toFixed(2) : "0"} </b>
                </p>
                <div style={{ margin: "0", padding: "0" }}>
                    {presupuesto?.actualizadoDescuentoCliente ? (
                        <p className="text-success" style={{ fontSize: "0.9rem", paddingLeft: "3px" }}>
                            <b>El descuento ha sido actualizado.</b>
                        </p>
                    ) : (null)  
                    }
                </div>
            </div>
            {/* Total con descuento */}
            <div className='ShoppingCart__items-7 ShopRow_col' style={{ paddingTop : "60px"}}>
                <p className="fw-bolder" style={{ margin: "0", padding: "0", fontSize: "0.9rem" }}><b>{presupuesto?.moneda?.simbolo} {data?.montoSubtotalDto.toFixed(2)} </b></p>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        presupuesto: state.presupuestos.presupuesto,
    }),
    dispatch => ({
        requestEliminarPresupuestoDetalle: (presupuestoId, item) => dispatch(requestEliminarPresupuestoDetalle(presupuestoId, item)),
    })
)(ShopRow);