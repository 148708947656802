import React from "react";
import colores from "../shared/constants/styles";
import Insta from "../assets/icons/Insta.svg";
import Facebook from "../assets/icons/Facebook.svg";
import Twitter from "../assets/icons/Twitter.svg";
import Youtube from "../assets/icons/Youtube.svg";
import Linkedin from "../assets/icons/Linkedin.svg";
import afip from "../assets/images/afip.jpg";
import barbieriMarca from "../assets/images/Barbieri-MarcaGrafica-Footer.svg";
import { Link } from "react-router-dom";
import rutas from "./constants/routes";

const FooterPage = () => {
  const style = { background: colores.grisOscuro, color: "white" };

  return (
    <footer style={style} className="p-5">
      <div className="row d-flex justify-content-around">
        {/* <div className="col-lg-1 col-md-2 mb-4"><img className="mx-3" style={{width:64, height:64}} src={sustentable} alt=""></img>
          </div> */}

        <div className="col-lg-3 col-md-3 d-block-sm mb-3">
          <h5>¿DÓNDE ESTAMOS?</h5>
          <div>
            <p>
              BUENOS AIRES -ARGENTINA
            </p>
          </div>
        </div>

        <div className="col-lg-3 col-md-3 d-block-sm mb-3">
          <div style={style}>
            <ul style={{ listStyle: "none" }}>
              <li>
                <a
                  style={{ textDecoration: "none" }}
                  href=""
                  target="blank"
                >
                  <span style={{ color: "white" }}>Quienes somos</span>
                </a>
              </li>
              <li>
                <a
                  style={{ textDecoration: "none" }}
                  href=""
                  target="blank"
                >
                  <span style={{ color: "white" }}>
                    Términos y Condiciones
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 d-block-sm mb-3">
          <ul style={{ listStyle: "none", listStylePosition: "inside" }}>
            <h5>Servicio al cliente</h5>
            <li>
              <a
                style={{ textDecoration: "none" }}
                href=""
                target="blank"
              >
                <span style={{ color: "white" }}>Capacitate</span>
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: "none" }}
                href=""
                target="blank"
              >
                <span style={{ color: "white" }}>Preguntas frecuentes</span>
              </a>
            </li>
            <li class="mobile-hide">
              <Link
                style={{ textDecoration: "none" }}
                href="#"
                to={rutas.FORM_CONTACT}
              >
                <span style={{ color: "white" }}>Contacto</span>
              </Link>
            </li>
          </ul>
          <div className="mx-4 my-4">
            <a href="" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Insta}
                alt=""
              ></img>
            </a>
            <a href="" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Facebook}
                alt=""
              ></img>
            </a>
            <a href="" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Twitter}
                alt=""
              ></img>
            </a>
            <a href="" target="blank">
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Youtube}
                alt=""
              ></img>
            </a>
            <a
              href=""
              target="blank"
            >
              <img
                className="mx-1 my-1"
                style={{ width: 25, height: 25 }}
                src={Linkedin}
                alt=""
              ></img>
            </a>
          </div>
        </div>
      </div>
      <span>
        <hr></hr>
      </span>
      <span style={{ fontSize: 14 }}>
        Todos los derechos reservados.
      </span>
    </footer>
  );
};

export default FooterPage;
