import React, {useEffect} from 'react';
import { useFormik } from "formik";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from "yup";
import { changePasswordEmail } from '../../actions/auth';
import { useHistory } from "react-router-dom";
import rutas from '../../routes/constants/routes';
import MyUserService from "../../services/myUser";
import TokenService from '../../services/token';

const ChangePasswordEmail = (props) => {
    
    let { Email } = useParams();
    let { Code } = useParams();

    const myUser = MyUserService.getMyUser();
    const email = TokenService.hasSession() ? myUser.email : Email;
    const history = useHistory();

    useEffect(() => {
        
        if(props.successPasswordEmail){
            history.push(rutas.LOGIN);
            window.location.reload();
        }

    }, [props])

    const formik = useFormik({
        initialValues: {
            NewPassword: "",
            PasswordCopy: "",
            Email: email,
            Password: "",
            ConfirmationCode: Code,
            ChangePassword: false
        },
        validationSchema: Yup.object({
            NewPassword: Yup.string().required("Ingrese una contraseña")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Debe tener: 1 mayúscula, 1 número, 1 caracter especial."
            ),
            PasswordCopy: Yup.string().required("Ingrese nuevamente la contraseña")
            .test('passwords-match', 'Las contraseñas deben coincidir', function(value){
                return this.parent.NewPassword === value
            }),
            Password: Yup.string()
        }),
        onSubmit: (data) => {
            props.changePasswordEmail(data);
        }
    })

    return (
        <>
        <div>
            <div className="d-grid gap-2 col-12 col-md-5 mx-auto" style={{marginTop:"2.25rem", marginBottom:"2.25rem"}}>
                <div className="container" style={{margin: "20px 0px"}}>
                    <p className="text-center">Cambiar contraseña para el cliente con email:</p>
                    <div className="d-flex flex-row" style={{backgroundColor: "#e2e2e2", padding: "15px", borderRadius: "10px"}}>
                        <div className="float-start" style={{fontWeight:"bold", marginRight:"1.25rem"}}>
                            <h4>Email</h4>
                        </div>
                        <div className="float-end" style={{color:"gray", fontWeight:200}}>
                            <h4>{email}</h4>
                        </div>
                    </div>
                </div>
                <form className="container" onSubmit={formik.handleSubmit}>
                    { TokenService.hasSession()
                        && 
                            (<div className="mb-3">
                            <label for="Password" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem"}}>Ingrese contraseña actual:</label>
                            <input  onChange={formik.handleChange} error={formik.errors.password} type="password" className="form-control" id="Password" name='Password' style={{borderColor:"black",padding:"0.625rem"}}></input>
                            {formik.touched.Password && formik.errors.Password ? (
                            <div className='text-danger'>{formik.errors.Password}</div>
                            ): null}
                            </div>
                            )
                    }
                    <div className="mb-3">
                        <label for="NewPassword" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem"}}>Nueva contraseña:</label>
                        <input  onChange={formik.handleChange} error={formik.errors.NewPassword} type="password" className="form-control" id="NewPassword" name='NewPassword' style={{borderColor:"black",padding:"0.625rem"}}></input>
                        {formik.touched.NewPassword && formik.errors.NewPassword ? (
                        <div className='text-danger'>{formik.errors.NewPassword}</div>
                        ): null}
                        <p>Debe tener: 1 mayúscula, 1 número, 1 caracter especial.</p>
                    </div>
                    <div className="mb-3">
                        <label for="PasswordCopy" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem"}}>Repetir contraseña:</label>
                        <input  onChange={formik.handleChange} error={formik.errors.PasswordCopy} type="password" className="form-control" id="PasswordCopy" name='PasswordCopy' style={{borderColor:"black",padding:"0.625rem"}}></input>
                        {formik.touched.PasswordCopy && formik.errors.PasswordCopy ? (
                        <div className='text-danger'>{formik.errors.PasswordCopy}</div>
                        ): null}
                    </div>
                    <div className="d-grid gap-2 col-12 col-md-6 mx-auto">
                        <button className={`btn btn-danger ${props.loading ? "disabled" : ""}`} style={{backgroundColor: "#E86A25", padding: "0.625rem", marginTop:"1.25rem"}} type="submit">Confirmar</button>
                    </div>
                    {
                    props?.errorPasswordEmail ?
                        <div class="alert alert-danger" role="alert" style={{marginTop: "1rem"}} >
                        { props.error.response.data.message }
                    </div>
                        :
                        ""
                    }
                </form>
            </div>
        </div>
    </>
    )
}

export default connect(
    state => ({
        loading: state.auth.loading,
        successPasswordEmail: state.auth.successPasswordEmail,
        errorPasswordEmail: state.auth.errorPasswordEmail,
        error: state.auth.error
    }),
    dispatch => ({
        changePasswordEmail: (data) => dispatch(changePasswordEmail(data))
    })
)(ChangePasswordEmail);
