export const Types= {
    PRESUPUESTO_LOADING: 'PRESUPUESTO_LOADING',
    PRESUPUESTO_CLOSE_DOWNLOAD: 'PRESUPUESTO_CLOSE_DOWNLOAD',
    PRESUPUESTOSCONFIRMACION_FETCH_REQUESTED: 'PRESUPUESTOSCONFIRMACION_FETCH_REQUESTED',
    PRESUPUESTOSCONFIRMACION_FETCH_SUCCEEDED: 'PRESUPUESTOSCONFIRMACION_FETCH_SUCCEEDED',
    PRESUPUESTOSCONFIRMACION_FETCH_ERROR: 'PRESUPUESTOSCONFIRMACION_FETCH_ERROR',
    PRESUPUESTOSCONFIRM_FETCH_REQUESTED: 'PRESUPUESTOSCONFIRM_FETCH_REQUESTED',
    PRESUPUESTOSCONFIRM_FETCH_SUCCEEDED: 'PRESUPUESTOSCONFIRM_FETCH_SUCCEEDED',
    PRESUPUESTOSCONFIRM_FETCH_ERROR: 'PRESUPUESTOSCONFIRM_FETCH_ERROR',
    PRESUPUESTOS_FETCH_REQUESTED: 'PRESUPUESTOS_FETCH_REQUESTED',
    PRESUPUESTOS_FETCH_SUCCEEDED: 'PRESUPUESTOS_FETCH_SUCCEEDED',
    PRESUPUESTOS_FETCH_ERROR: 'PRESUPUESTOS_FETCH_ERROR',
    PRESUPUESTOS_DOWNLOAD_REQUESTED: 'PRESUPUESTOS_DOWNLOAD_REQUESTED',
    PRESUPUESTOS_DOWNLOAD_SUCCEEDED: 'PRESUPUESTOS_DOWNLOAD_SUCCEEDED',
    PRESUPUESTOS_DOWNLOAD_ERROR: 'PRESUPUESTOS_DOWNLOAD_ERROR',
    PRESUPUESTOS_CLIENTES_FETCH_REQUESTED: 'PRESUPUESTOS_CLIENTES_FETCH_REQUESTED',
    PRESUPUESTOS_CLIENTES_FETCH_SUCCEEDED: 'PRESUPUESTOS_CLIENTES_FETCH_SUCCEEDED',
    PRESUPUESTOS_CLIENTES_FETCH_ERROR: 'PRESUPUESTOS_CLIENTES_FETCH_ERROR',
    PRESUPUESTOS_CLIENTES_SELECTED_REQUESTED: 'PRESUPUESTOS_CLIENTES_SELECTED_REQUESTED',
    PRESUPUESTOS_CLIENTES_SELECTED_SUCCEEDED: 'PRESUPUESTOS_CLIENTES_SELECTED_SUCCEEDED',
    PRESUPUESTOS_CLIENTES_SELECTED_ERROR: 'PRESUPUESTOS_CLIENTES_SELECTED_ERROR',
    PRESUPUESTOS_FIND_REQUESTED: 'PRESUPUESTOS_FIND_REQUESTED',
    PRESUPUESTOS_FIND_SUCCEEDED: 'PRESUPUESTOS_FIND_SUCCEEDED',
    PRESUPUESTOS_FIND_ERROR: 'PRESUPUESTOS_FIND_ERROR',
    PRESUPUESTOS_SEARCH_REQUESTED: 'PRESUPUESTOS_SEARCH_REQUESTED',
    PRESUPUESTOS_SEARCH_SUCCEEDED: 'PRESUPUESTOS_SEARCH_SUCCEEDED',
    PRESUPUESTOS_SEARCH_ERROR: 'PRESUPUESTOS_SEARCH_ERROR',
    PRESUPUESTOS_FETCH_LATEST_REQUESTED: 'PRESUPUESTOS_FETCH_LATEST_REQUESTED',
    PRESUPUESTOS_FETCH_LATEST_SUCCEEDED: 'PRESUPUESTOS_FETCH_LATEST_SUCCEEDED',
    PRESUPUESTOS_FETCH_LATEST_ERROR: 'PRESUPUESTOS_FETCH_LATEST_ERROR',
    PRESUPUESTOS_DETALLES_REQUESTED: 'PRESUPUESTOS_DETALLES_REQUESTED',
    PRESUPUESTOS_DETALLES_SUCCEEDED: 'PRESUPUESTOS_DETALLES_SUCCEEDED',
    PRESUPUESTOS_DETALLES_ERROR: 'PRESUPUESTOS_DETALLES_ERROR',
    PRESUPUESTOS_GET_DETALLES_REQUESTED: 'PRESUPUESTOS_GET_DETALLES_REQUESTED',
    PRESUPUESTOS_GET_DETALLES_SUCCEEDED: 'PRESUPUESTOS_GET_DETALLES_SUCCEEDED',
    PRESUPUESTOS_GET_DETALLES_ERROR: 'PRESUPUESTOS_GET_DETALLES_ERROR',
    PRESUPUESTOS_ADD_REQUESTED: 'PRESUPUESTOS_ADD_REQUESTED',
    PRESUPUESTOS_ADD_SUCCEEDED: 'PRESUPUESTOS_ADD_SUCCEEDED',
    PRESUPUESTOS_ADD_ERROR: 'PRESUPUESTOS_ADD_ERROR',
    PRESUPUESTOS_DELETE_REQUESTED: 'PRESUPUESTOS_DELETE_REQUESTED',
    PRESUPUESTOS_DELETE_SUCCEEDED: 'PRESUPUESTOS_DELETE_SUCCEEDED',
    PRESUPUESTOS_DELETE_ERROR: 'PRESUPUESTOS_DELETE_ERROR',
    PRESUPUESTOS_EDIT_REQUESTED: 'PRESUPUESTOS_EDIT_REQUESTED',
    PRESUPUESTOS_EDIT_SUCCEEDED: 'PRESUPUESTOS_EDIT_SUCCEEDED',
    PRESUPUESTOS_EDIT_ERROR: 'PRESUPUESTOS_EDIT_ERROR',
    PRESUPUESTOS_PRECONFIRM_DATA_REQUESTED: 'PRESUPUESTOS_PRECONFIRM_DATA_REQUESTED',
    PRESUPUESTOS_PRECONFIRM_DATA_SUCCEEDED: 'PRESUPUESTOS_PRECONFIRM_DATA_SUCCEEDED',
    PRESUPUESTOS_PRECONFIRM_DATA_ERROR: 'PRESUPUESTOS_PRECONFIRM_DATA_ERROR',
    PRESUPUESTOS_CANCELAR_REQUESTED: 'PRESUPUESTOS_CANCELAR_REQUESTED',
    PRESUPUESTOS_CANCELAR_SUCCEDED: 'PRESUPUESTOS_CANCELAR_SUCCEDED',
    PRESUPUESTOS_CANCELAR_ERROR: 'PRESUPUESTOS_CANCELAR_ERROR',
    PRESUPUESTOS_EDITSHOPCART_REQUESTED: 'PRESUPUESTOS_EDITSHOPCART_REQUESTED',
    PRESUPUESTOS_EDITSHOPCART_SUCCEEDED: 'PRESUPUESTOS_EDITSHOPCART_SUCCEEDED',
    PRESUPUESTOS_EDITSHOPCART_ERROR: 'PRESUPUESTOS_EDITSHOPCART_ERROR',
    PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_REQUESTED: 'PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_REQUESTED',
    PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_SUCCEEDED: 'PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_SUCCEEDED',
    PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_ERROR: 'PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_ERROR',
    PRESUPUESTOS_PRECONFIRM_SUCCESS_RESET:'PRESUPUESTOS_PRECONFIRM_SUCCESS_RESET',
    PRESUPUESTO_ADD_PRODUCT_FALSE: 'PRESUPUESTO_ADD_PRODUCT_FALSE',    
    PRESUPUESTOS_CANT_CARRITO_REQUESTED: 'PRESUPUESTOS_CANT_CARRITO_REQUESTED',
    PRESUPUESTOS_CANT_CARRITO_SUCCEEDED: 'PRESUPUESTOS_CANT_CARRITO_SUCCEEDED',
    PRESUPUESTOS_CANT_CARRITO_ERROR: 'PRESUPUESTOS_CANT_CARRITO_ERRORR'
};

export const startLoading = () => ({type: Types.PRESUPUESTO_LOADING});
export const closeDownloadFile = () => ({type: Types.PRESUPUESTO_CLOSE_DOWNLOAD});
export const addProductFalse = () => ({type: Types.PRESUPUESTO_ADD_PRODUCT_FALSE});

export const requestDownloadFile = (numero, comprobante, ruta) => ({type: Types.PRESUPUESTOS_DOWNLOAD_REQUESTED, numero, comprobante, ruta});
export const receiveDownloadFile = file => ({type: Types.PRESUPUESTOS_DOWNLOAD_SUCCEEDED, file})
export const receiveDownloadFileError = err => ({type: Types.PRESUPUESTOS_DOWNLOAD_ERROR, err})

export const requestPresupuestos = (page, clienteId) => ({type: Types.PRESUPUESTOS_FETCH_REQUESTED, page, clienteId});
export const receivePresupuestos = presupuestos => ({type: Types.PRESUPUESTOS_FETCH_SUCCEEDED, presupuestos})
export const receivePresupuestosError = err => ({type: Types.PRESUPUESTOS_FETCH_ERROR, err})

export const requestPresupuestoConfirm = (presupuestoId) => ({type: Types.PRESUPUESTOSCONFIRM_FETCH_REQUESTED, presupuestoId});
export const receivePresupuestosConfirm = presupuestoConfirm => ({type: Types.PRESUPUESTOSCONFIRM_FETCH_SUCCEEDED, presupuestoConfirm})
export const receivePresupuestosErrorConfirm = err => ({type: Types.PRESUPUESTOSCONFIRM_FETCH_ERROR, err})

export const requestConfirmacionPresupuesto = (idPresupuesto, observaciones, esPedidoFirme) => ({type: Types.PRESUPUESTOSCONFIRMACION_FETCH_REQUESTED, idPresupuesto, observaciones, esPedidoFirme});
export const receiveConfirmacionPresupuesto = success => ({type: Types.PRESUPUESTOSCONFIRMACION_FETCH_SUCCEEDED, success})
export const receiveConfirmacionPresupuestoError = err => ({type: Types.PRESUPUESTOSCONFIRMACION_FETCH_ERROR, err})

export const requestFetchClientes = (id) => ({type: Types.PRESUPUESTOS_CLIENTES_FETCH_REQUESTED, id});
export const receiveFetchClientes = VenClientes => ({type: Types.PRESUPUESTOS_CLIENTES_FETCH_SUCCEEDED, VenClientes})
export const receiveFetchClientesError = err => ({type: Types.PRESUPUESTOS_CLIENTES_SELECTED_ERROR, err})

export const requestSelectedClient = (id) => ({type: Types.PRESUPUESTOS_CLIENTES_SELECTED_REQUESTED, id});
export const receiveSelectedClient = clienteSelected => ({type: Types.PRESUPUESTOS_CLIENTES_SELECTED_SUCCEEDED, clienteSelected})
export const receiveSelectedClientError = err => ({type: Types.PRESUPUESTOS_CLIENTES_SELECTED_REQUESTED, err})

export const requestPresupuesto = (id) => ({type: Types.PRESUPUESTOS_FIND_REQUESTED, id});
export const receivePresupuestoSucceeded = Presupuestos => ({type: Types.PRESUPUESTOS_FIND_SUCCEEDED, Presupuestos})
export const receivePresupuestoError = err => ({type: Types.PRESUPUESTOS_FIND_ERROR, err})

export const requestPresupuestosSearch = (search) => ({type: Types.PRESUPUESTOS_SEARCH_REQUESTED, search});
export const receivePresupuestosSearchSucceeded = Presupuestos => ({type: Types.PRESUPUESTOS_SEARCH_SUCCEEDED, Presupuestos})
export const receivePresupuestosSearchError = err => ({type: Types.PRESUPUESTOS_SEARCH_ERROR, err})

export const requestUltimosPresupuestos = (id) => ({type: Types.PRESUPUESTOS_FETCH_LATEST_REQUESTED, id});
export const receiveUltimosPresupuestos = ultimosPresupuestos => ({type: Types.PRESUPUESTOS_FETCH_LATEST_SUCCEEDED, ultimosPresupuestos})
export const receiveUltimosPresupuestosError = err => ({type: Types.PRESUPUESTOS_FETCH_LATEST_ERROR, err})

export const requestPresupuestoDetalles = (contactoId, clienteId) => ({type: Types.PRESUPUESTOS_DETALLES_REQUESTED, contactoId, clienteId});
export const receivePresupuestoDetalles = presupuesto => ({type: Types.PRESUPUESTOS_DETALLES_SUCCEEDED, presupuesto})
export const receivePresupuestoDetallesError = error => ({type: Types.PRESUPUESTOS_DETALLES_ERROR, error})

export const requestGetPresupuestoDetalles = (userId, clienteId) => ({type: Types.PRESUPUESTOS_GET_DETALLES_REQUESTED, userId, clienteId});
export const receiveGetPresupuestoDetalles = presupuesto => ({type: Types.PRESUPUESTOS_GET_DETALLES_SUCCEEDED, presupuesto})
export const receiveGetPresupuestoDetallesError = error => ({type: Types.PRESUPUESTOS_GET_DETALLES_ERROR, error})

export const requestPreconfirmData = (presupuestoId) => ({type: Types.PRESUPUESTOS_PRECONFIRM_DATA_REQUESTED, presupuestoId});
export const receivePreconfirmData = preconfirmData => ({type: Types.PRESUPUESTOS_PRECONFIRM_DATA_SUCCEEDED, preconfirmData})
export const receivePreconfirmDataError = preconfirmData => ({type: Types.PRESUPUESTOS_PRECONFIRM_DATA_ERROR, preconfirmData})

export const requestCancelarPresupuesto = (presupuestoId) => ({type: Types.PRESUPUESTOS_CANCELAR_REQUESTED, presupuestoId});
export const receiveCancelarPresupuestoSucceeded = isCancel => ({type: Types.PRESUPUESTOS_CANCELAR_SUCCEDED, isCancel});
export const receiveCancelarPresupuestoError = err => ({type: Types.PRESUPUESTOS_CANCELAR_ERROR, err});

/* ABM DE PRESUPUESTOS */

// Agrega un producto a un presupuesto
export const requestPresupuestoAdd = (producto) => ({type: Types.PRESUPUESTOS_ADD_REQUESTED, producto});
export const receivePresupuestoAdd = presupuesto => ({type: Types.PRESUPUESTOS_ADD_SUCCEEDED, presupuesto})
export const receivePresupuestoAddError = err => ({type: Types.PRESUPUESTOS_ADD_ERROR, err})

// Elimina un producto del carrito/presupuesto
export const requestEliminarPresupuestoDetalle = (presupuestoId, item) => ({type: Types.PRESUPUESTOS_DELETE_REQUESTED, presupuestoId, item});
export const receiveEliminarPresupuestoDetalle = presupuesto => ({type: Types.PRESUPUESTOS_DELETE_SUCCEEDED, presupuesto})
export const receiveEliminarPresupuestoDetalleError = err => ({type: Types.PRESUPUESTOS_DELETE_ERROR, err})

// Edita cantidad de un item del carrito/presupuesto
export const requestEditarCantPresupuestoDetalle = (cant, presupuestoId, item) => ({type: Types.PRESUPUESTOS_EDIT_REQUESTED, cant, presupuestoId, item});
export const receiveEditarCantPresupuestoDetalle = presupuesto => ({type: Types.PRESUPUESTOS_EDIT_SUCCEEDED, presupuesto})
export const receiveEditarCantPresupuestoDetalleError = err => ({type: Types.PRESUPUESTOS_EDIT_ERROR, err})

// Edita cantidad de un item del carrito/presupuesto
export const requestEditarPresupuesto = (presupuesto, presupuestoId) => ({type: Types.PRESUPUESTOS_EDITSHOPCART_REQUESTED, presupuesto, presupuestoId});
export const receiveEditarPresupuesto = presupuesto => ({type: Types.PRESUPUESTOS_EDITSHOPCART_SUCCEEDED, presupuesto})
export const receiveEditarPresupuestoError = err => ({type: Types.PRESUPUESTOS_EDITSHOPCART_ERROR, err})

// Preconfirma presupuesto -> Edita un registro de presupuesto
export const requestPreconfirmPresupuesto = (presupuesto) => ({type: Types.PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_REQUESTED, presupuesto});
export const receivePreconfirmPresupuesto = successPreconfirm => ({type: Types.PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_SUCCEEDED, successPreconfirm})
export const resetReceivePreconfirmPresupuestoStatus = success => ({type: Types.PRESUPUESTOS_PRECONFIRM_SUCCESS_RESET, success:false})
export const receivePreconfirmPresupuestoError = err => ({type: Types.PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_ERROR, err})

export const requestCantInCarrito = (contactoId, clienteId) => ({type: Types.PRESUPUESTOS_CANT_CARRITO_REQUESTED, contactoId, clienteId});
export const receiveCantInCarrito = cantidad => ({type: Types.PRESUPUESTOS_CANT_CARRITO_SUCCEEDED, cantidad})
export const receiveCantInCarritoError = err => ({type: Types.PRESUPUESTOS_CANT_CARRITO_ERROR, err})
