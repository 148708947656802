const SWOW_POLICY = 'showPolicy';

export default class MyUserService {
    static getMyUser() {
        return JSON.parse(window.localStorage.getItem('LocalSession'));
    }
    static getMyGroup() {
        return JSON.parse(window.localStorage.getItem('grupoId'));
    }
    static setMostrarPolitica(mostrarPoliticasReclamo) {
        localStorage.setItem(SWOW_POLICY, mostrarPoliticasReclamo);
    }
    static getMostrarPolitica() {
        return localStorage.getItem(SWOW_POLICY);
    }
}