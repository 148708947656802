import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { downloadPoliticaReclamo } from "../../../actions/configuracion";
import Checkbox from "@mui/material/Checkbox";
import Link from '@mui/material/Link';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface DownloadPoliticaReclamoProps {
    checked: boolean;
    setChecked: (checked: boolean)=> void;
    loadingDownload: boolean;
}

const DownloadPoliticaReclamo = (props: DownloadPoliticaReclamoProps) => {

    const dispatch = useDispatch();
    const handleDownloadPoliticaReclamo = () => {
        dispatch(downloadPoliticaReclamo());
    };
    const handleChange = (event: any) => {
        props.setChecked(event.target.checked);
    };

    return (
        <div>
            { props.loadingDownload ? (
            <Box display="flex" alignItems="center">
                <CircularProgress size={24} />
                <span style={{ marginLeft: "8px" }}>Descargando...</span>
            </Box>
            ) : (
            <Link
                component="button"
                variant="body2"
                onClick={handleDownloadPoliticaReclamo}
                underline="hover"
            >
                Política de gestión, revisión y resolución de reclamos
            </Link>
            )}
            <Box display="flex" alignItems="center">
                <Checkbox
                    checked={props.checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                />
                He leído y acepto las nuevas políticas de reclamos
            
            </Box>
        </div>
    );
};


export default connect
(
    (state: any) => ({
        loadingDownload: state.configuracion.loadingDownload,
        successDownloadPoliticaReclamos: state.configuracion.successDownloadPoliticaReclamos
    }),
    dispatch => ({
        downloadPoliticaReclamo: ()=> dispatch(downloadPoliticaReclamo())
    })
)(DownloadPoliticaReclamo);
