import {call, put, takeEvery, fork} from 'redux-saga/effects';
import * as actions from '../actions/configuracion';
import configuracion from '../services/configuracion';

function* updateShoopEnabled({formData}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(configuracion.updateShoopEnabled, formData);
        if(response.data){
            yield put(actions.updateShoopEnabledSuccess());
        } 
    } catch (err) {
        yield put(actions.updateShoopEnabledError(err));
        console.log(err)
    }
}
function* updateTerminosCondiciones({formData}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(configuracion.updateTerminosCondiciones, formData);
        if(response.data){
            yield put(actions.updateTerminosCondicionesSuccess());
        } 
    } catch (err) {
        yield put(actions.updateTerminosCondicionesError(err));
        console.log(err)
    }
}
function* getTerminosCondiciones() {
    yield put(actions.startLoading());
    try {
        const response = yield call(configuracion.getTerminosCondiciones);
        if(response.data){
            yield put(actions.getTerminosCondicionesSuccess(response.data));
        } 
    } catch (err) {
        yield put(actions.getTerminosCondicionesError(err));
        console.log(err)
    }
}
function* uploadListPrecios({formData}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(configuracion.uploadListPrecios, formData);
        if(response.httpStatusCode){
            yield put(actions.uploadListaPrecioSuccess());
        }
    } catch (err) {
        yield put(actions.uploadListaPrecioError(err));
        console.log(err)
    }
}
function* uploadBancoImagenes({formData}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(configuracion.uploadBancoImagenes, formData);
        if(response.httpStatusCode){
            yield put(actions.uploadBancoImagenesSuccess());
        } 
    } catch (err) {
        yield put(actions.uploadBancoImagenesError(err));
        console.log(err)
    }
}
function* uploadManualMarca({formData}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(configuracion.uploadManualMarca, formData);
        if(response.httpStatusCode){
            yield put(actions.uploadManualMarcaSuccess());
        } 
    } catch (err) {
        yield put(actions.uploadManualMarcaError(err));
        console.log(err)
    }
}

function* updatePoliticaReclamos({formData}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(configuracion.updatePoliticaReclamos, formData);
        if(response.data){
            yield put(actions.updatePoliticaReclamoSuccess());
        } 
    } catch (err) {
        yield put(actions.updatePoliticaReclamoError(err));
        console.log(err)
    }
}

function* downloadPoliticaReclamos() {
    yield put(actions.startDownloadLoading());
    try {
        const response = yield call(configuracion.downloadPoliticaReclamos);
        if(response){
            yield put(actions.downloadPoliticaReclamoSuccess());
        } else {
            yield put(actions.downloadPoliticaReclamoError(true));
        }
    } catch (err) {
        yield put(actions.downloadPoliticaReclamoError(err));
        console.log(err)
    }
}

function*  watchUpdateShoopEnabled() {
    yield takeEvery(actions.Types.CONFIGURACION_UPDATE_SHOOP_ENABLED_REQUEST, updateShoopEnabled);
}
function*  watchUploadListPrecios() {
    yield takeEvery(actions.Types.CONFIGURACION_UPLOAD_LISTA_PRECIO, uploadListPrecios);
}
function*  watchUploadBancoImagenes() {
    yield takeEvery(actions.Types.CONFIGURACION_UPLOAD_BANCO_IMAGENES, uploadBancoImagenes);
}
function*  watchUploadManualMarca() {
    yield takeEvery(actions.Types.CONFIGURACION_UPLOAD_MANUAL_MARCA, uploadManualMarca);
}
function*  watchUploadTerminosCondiciones() {
    yield takeEvery(actions.Types.CONFIGURACION_UPDATE_TERMINOS_CONDICIONES, updateTerminosCondiciones);
}
function*  watchGetTerminosCondiciones() {
    yield takeEvery(actions.Types.CONFIGURACION_GET_TERMINOS_CONDICIONES, getTerminosCondiciones);
}
function*  watchUpdatePoliticaReclamos() {
    yield takeEvery(actions.Types.CONFIGURACION_UPDATE_POLITICA_RECLAMO, updatePoliticaReclamos);
}
function*  watchDownloadPoliticaReclamos() {
    yield takeEvery(actions.Types.CONFIGURACION_DOWNLOAD_POLITICA_RECLAMO, downloadPoliticaReclamos);
}

const configuracionSagas = [  
    fork(watchUpdateShoopEnabled),
    fork(watchUploadListPrecios),
    fork(watchUploadBancoImagenes),
    fork(watchUploadManualMarca),
    fork(watchUploadTerminosCondiciones),
    fork(watchGetTerminosCondiciones),
    fork(watchUpdatePoliticaReclamos),
    fork(watchDownloadPoliticaReclamos)
]

export default configuracionSagas;