import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { requestProductosPorGrupo, requestProductosSearch } from "../../actions/producto";
import { requestGrupos } from "../../actions/params";
import CardProduct from "./cardProduct";
import Dolar from "../../routes/dolar";
import colores from "../../shared/constants/styles";
import { isAllowed } from "../../shared/utils/permissions";
import MuiCustomInput from "./MuiCustomInput";
import CardMessage from "./CardMessage";
import LoadingCart from "../ShoppingCart/LoadingCart";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Container, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

const Productos = (props) => {
  const { id, prod } = useParams();
  const dispatch = useDispatch();
  const { grupos } = props;
  const [categoria, setCategoria] = useState(null);
  const [descripcion, setDescripcion] = useState("");
  const [flagForm, setFlagForm] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const [grupo, setGrupo] = useState();
  const [page, setPage]= useState(1);
  const [productos, setProductos]= useState([]);
  const [loading, setLoading]= useState(true);
  const [allItems, setAllItems]= useState(true);

  const gruposDropdown = [
    ...new Map(productos?.map((item) => [item["gruposProdId"], item])).values(),
  ];
  const localStorage = JSON.parse(window.localStorage.getItem("LocalSession"));
  const selectedClient = JSON.parse(
    window.localStorage.getItem("selectedClient")
  );

  useEffect(() => {
    window.localStorage.setItem("grupoId", JSON.stringify(id));
    setCategoria(null);
    if (isAllowed(18)) {
      setCategoria(id);
    }
    dispatch(requestGrupos());
  }, []);

  useEffect(() => {
    let g = grupos?.find(
      (g) => g.grupoId.toLocaleLowerCase() === id.toLocaleLowerCase()
    );
    setGrupo(g);
  }, [grupos]);

  const handleChange = (value) => {
    setFlagForm(true);
    setCategoria(id);
    setBusqueda(value);
  };

  const handleSearch = (value) => {
    if(value && value != ''){
      setLoading(true);
      setPage(1);
      props.requestProductosSearch(categoria, value);
    }
  };

  useEffect(() => {
    if(descripcion.length <=0){
      setProductos([])
      setLoading(true);
      if (selectedClient) {
        props.requestProductosPorGrupo(page, id, selectedClient[0].id);
      } else {
        if (localStorage.tipoRol === 3) {
          props.requestProductosPorGrupo(page, id, localStorage.clienteId);
        }
      }      
    } 
  }, [descripcion]);

  const handleVerMas= () => {
    const newPage= page+ 1;
    setPage(newPage);
    if (selectedClient) {
      props.requestProductosPorGrupo(newPage, id, selectedClient[0].id);
    } else {
      if (localStorage.tipoRol === 3) {
        props.requestProductosPorGrupo(newPage, id, localStorage.clienteId);
      }
    }
  }

  useEffect(() => {
    if(props.success) {
      setLoading(false);
      if(props.productos.length > 0){
        setAllItems(false);
        const listado_productos = productos.concat(props.productos);
        setProductos(listado_productos)
      } else {
        setAllItems(true);
      }
    }
  }, [props.productos]);

  
  useEffect(() => {
    if(props.success) {
      setLoading(false);
      if(props.busquedaProductos.length > 0){
        setProductos(props.busquedaProductos)
      } else {
        setProductos([])
      }
    }
  }, [props.busquedaProductos]);

  return (
    <div style={{ backgroundColor: colores.grisClaro }}>
      <div style={{ width: "100%" }}>
        {props.loading ? (
          <div style={{ height: "120px" }}></div>
        ) : (
          <img src={grupo?.imagen} style={{ width: "100%" }} alt="grupo" />
        )}
      </div>
      <Dolar></Dolar>
      {/* HEADER */}
      <div className="container-fluid" style={{width: "100%", overflow:"hidden", clear:"both", marginBottom: "1.85rem"}}>
        <div className='d-flex justify-content-center'>
          <hr style={{width:"90%", color:"black", marginTop: "0"}}></hr>
        </div>
        <Container maxWidth="w-100" style={{paddingRight:"60px",paddingLeft:"60px"}}>
        <div className='d-flex justify-content-between flex-wrap align-items-center ' style={{marginBottom:"0.600rem", paddingRight:"3.5rem"}}>
          <h1 className="fw-bolder" style={{fontSize: "bolder"}}>PRODUCTOS</h1>
        </div>        
        </Container>
      </div>
      
      {/* FILTRO Y LISTADO */}
      <div className='container-fluid mx-0 px-0 w-100' style={{margin:"2rem"}}>
          <div className="container-fluid" style={{backgroundColor: "white", width: "100%", overflow:"hidden", clear:"both", marginBottom: "1.85rem",paddingLeft:"76px",paddingRight:"76px"}}>
            <div className='d-flex justify-content-between' style={{margin:"2rem 0", marginLeft: "3.7rem"}}>
              <div>
                <h2>BÚSQUEDA</h2>
              </div>
            </div>
            <div className="container d-flex flex-row flex-wrap" style={{ marginBlock: "2rem" }}>
              <div className="container-fluid d-flex flex-row mb-3 flex-wrap" style={{ marginLeft: "3.7rem", padding: "1rem" }}>
                <div className="fluid d-flex flex-start flex-wrap">
                  <MuiCustomInput
                    component={"FormControl"}
                    handleChange={handleChange}
                    gruposDropdown={gruposDropdown}
                    productos={productos}
                    setFlagForm={setFlagForm}
                    flagForm={flagForm}
                  />
                  <MuiCustomInput
                    component={"TextField"}
                    onSearch={handleSearch}
                    value={descripcion}
                    setValue={setDescripcion}
                  />
                </div>
              </div>

              {loading ? (
                <LoadingCart></LoadingCart>
              ) : productos.length > 0 ? (
                busqueda === "" ? (
                  productos
                    .map(
                      (producto) =>
                        producto.id !== "" &&
                        (!prod || prod === producto.id) && (
                          <CardProduct key={producto.id} data={producto} />
                        )
                    )
                ) : (
                  productos
                    .map(
                      (producto) =>
                        producto.id !== "" &&
                        (!prod || prod === producto.id) &&
                        producto.gruposProdId === busqueda && (
                          <CardProduct key={producto.id} data={producto} />
                        )
                    )
                )
              ) : localStorage.tipoRol === 3 ? (
                  productos.length <= 0 ? <CardMessage cliente={true}></CardMessage>
                  : <LoadingCart></LoadingCart>
                
              ) : (
                productos.length <= 0 ? <CardMessage cliente={false}></CardMessage>
                  : <LoadingCart></LoadingCart>
              )}          
            </div>
            {props.productos.length > 0 && descripcion.length <= 0 && 
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button variant="outlined" onClick={handleVerMas} color="error">ver más...</Button>
              </Stack>   
            }
          
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    productos: state.producto.productos,
    busquedaProductos: state.producto.busquedaProductos,
    grupos: state.params.grupos,
    loading: state.producto.loading,
    success: state.producto.successSearchGrupo
  }),
  (dispatch) => ({
    requestProductosSearch: (id, search) => dispatch(requestProductosSearch(id, search)),
    requestProductosPorGrupo: (page, id, clienteId) => dispatch(requestProductosPorGrupo(page, id, clienteId)),
    requestGrupos: () => dispatch(requestGrupos()),
  })
)(Productos);