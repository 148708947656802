import { connect, useDispatch } from "react-redux";
import colores from "../../shared/constants/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getEstadoPedidoByPresupuestoId } from "../../actions/cuentacorriente";
import { useEffect } from "react";

interface IEstadoPedidoDetalleProps {
    presupuestoId: string;
    showHeader: boolean;
    estadopedidos: any;
    loading: any;
    setEmpty?: (empty: boolean) => void;
}

const EstadoPedidoDetalle = (props: IEstadoPedidoDetalleProps) => {
    const dispatch = useDispatch();
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');

    useEffect(() => {
        dispatch(getEstadoPedidoByPresupuestoId(localStorage.clienteId.trim(), props.presupuestoId));
    }, []);
    
    useEffect(() => {
        if(props.estadopedidos && props.setEmpty){
            props.setEmpty(props.estadopedidos.length <= 0);
        }
    }, [props.estadopedidos]);

    return (
        <div>
            {props.estadopedidos?.length > 0 && (
                <div className="container" style={{ width: "100%" }}>
                    <Accordion style={{ borderRadius: "0px" }}>
                        { props.showHeader && 
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    backgroundColor: colores.grisClaro,
                                    paddingTop: "0.3rem",
                                    paddingBottom: "0.8rem",
                                    margin: 0
                                }}
                            >
                                <h4 className="text-center" style={{ fontWeight: "lighter" }}>
                                ESTADO DE PEDIDO
                                </h4>
                            </AccordionSummary>
                        }
                        <AccordionDetails>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "white",
                                    paddingTop: "2.20rem",
                                    paddingBottom: "1.20rem",
                                    marginLeft: "3rem"
                                }}>
                            <div
                                className="row row-cols-12 row-cols-sm-12 row-cols-md-12 "
                                style={{ width: "90%" }}>
                                <div className="col" style={{ fontWeight: "bold", maxWidth: "15%" }}>
                                    <p style={{ fontSize: "15px" }}> Estado</p>
                                </div>
                                <div className="col" style={{ fontWeight: "bold", maxWidth: "5%" }}>
                                    <p style={{ fontSize: "15px" }}> Item</p>
                                </div>
                                <div className="col" style={{ fontWeight: "bold", maxWidth: "15%" }}>
                                    <p style={{ fontSize: "15px" }}>Id de producto</p>
                                </div>
                                <div className="col" style={{ fontWeight: "bold", maxWidth: "45%" }}>
                                    <p style={{ fontSize: "15px" }}>Descripción</p>
                                </div>
                                <div className="col" style={{ fontWeight: "bold", maxWidth: "10%" }}>
                                    <p style={{ fontSize: "15px" }} className="text-center">Cantidad</p>
                                </div>
                            </div>
                            {props.estadopedidos?.map((estadopedido: any) => (
                                <div key={estadopedido.$id} className="row row-cols-12 row-cols-sm-12 row-cols-md-12" style={{ width: "90%" }}>
                                    <div className="col" style={{ maxWidth: "15%" }}>
                                        <p style={{ fontSize: "15px" }}>
                                            {estadopedido.estado}
                                        </p>
                                    </div>
                                    <div className="col" style={{ maxWidth: "5%" }}>
                                        <p style={{ fontSize: "15px" }}>
                                            {estadopedido.item}
                                        </p>
                                    </div>
                                    <div className="col" style={{ maxWidth: "15%" }}>
                                        <p style={{ fontSize: "15px" }}>
                                            {estadopedido.productoId}
                                        </p>
                                    </div>
                                    <div className="col" style={{ maxWidth: "45%" }}>
                                        <p style={{ fontSize: "15px" }}>
                                            {estadopedido.descripcion}
                                        </p>
                                    </div>
                                    <div className="col" style={{ maxWidth: "10%" }}>
                                        <p style={{ fontSize: "15px" }} className="text-center">
                                            {estadopedido.cantidad}
                                        </p>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
        </div>
    )
}

export default connect(
    (state: any) => ({
        estadopedidos: state.cuentacorriente.estadopedidos,
        error: state.cuentacorriente.error,    
        loading: state.cuentacorriente.loading
    }),
    (dispatch) => ({
        getEstadoPedidoByPresupuestoId: (clienteId: any, presupuestoId: any) => dispatch(getEstadoPedidoByPresupuestoId(clienteId, presupuestoId)),
    })
)(EstadoPedidoDetalle);