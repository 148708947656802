import {call, put, takeEvery, fork} from 'redux-saga/effects'
import * as actions from '../actions/presupuesto';
import * as actionsProducts from '../actions/producto';
import PresupuestosService from '../services/presupuesto';

function* PresupuestoConfirmar({idPresupuesto, observaciones, esPedidoFirme}) {
    yield put(actions.startLoading());
    try {
        const presupuestoConfirmar = yield call(PresupuestosService.ConfirmarPresupusto, idPresupuesto, observaciones, esPedidoFirme);
        yield put(actions.receiveConfirmacionPresupuesto(presupuestoConfirmar));
    } catch (err) {
        yield put(actions.receiveConfirmacionPresupuestoError(err.response.data.message));
        console.log(err)
    }
}

function* fetchPresupuestoConfirm(data) {
    yield put(actions.startLoading());
    try {
        const presupuestoConfirm = yield call(PresupuestosService.fetchConfirm, data);
        yield put(actions.receivePresupuestosConfirm(presupuestoConfirm));
    } catch (err) {
        yield put(actions.receivePresupuestosErrorConfirm(err));
        console.log(err)
    }
}

function* fetchPresupuestos({ page, clienteId }) {
    yield put(actions.startLoading());
    try {
        const presupuestos = yield call(PresupuestosService.fetchAll, page, clienteId);
        yield put(actions.receivePresupuestos(presupuestos));
    } catch (err) {
        yield put(actions.receivePresupuestosError(err));
        console.log(err)
    }
}

// function* fetchPresupuestosBackup({clienteId}) {
//     yield put(actions.startLoading());
//     try {
//         const presupuestos = yield call(PresupuestosService.fetch, clienteId);
//         yield put(actions.receivePresupuestos(presupuestos));
//     } catch (err) {
//         yield put(actions.receivePresupuestosError(err));
//         console.log(err)
//     }
// }

function* findPresupuesto({id}) {
    yield put(actions.startLoading());
    try {
        const presupuesto = yield call(PresupuestosService.find, id);
        yield put(actions.receivePresupuestoSucceeded(presupuesto));
    } catch (err) {
        yield put(actions.receivePresupuestoError(err));
        console.log(err)
    }
}

function* fetchClientes({id}) {
    yield put(actions.startLoading());
    try {
        const VenClientes = yield call(PresupuestosService.fetchClientes, id);
        yield put(actions.receiveFetchClientes(VenClientes));
    } catch (err) {
        yield put(actions.receiveFetchClientesError(err));
    }
}

function* searchPresupuestos({search}) {
    yield put(actions.startLoading());
    try {
        const presupuestos = yield call(PresupuestosService.search, search);
        yield put(actions.receivePresupuestos(presupuestos));
    } catch (err) {
        yield put(actions.receivePresupuestoError(err));
        console.log(err)
    }
}

// busca últimos tres presupuestos 
function* fetchLatestPresupuestos({id}) {
    yield put(actions.startLoading());
    try {
        const ultimosPresupuestos = yield call(PresupuestosService.fetchLatest, id);
        yield put(actions.receiveUltimosPresupuestos(ultimosPresupuestos));
    } catch (err) {
        yield put(actions.receiveUltimosPresupuestosError(err));
        console.log(err)
    }
}

// busca presupuesto en estado nuevo (Carrito)
function* findCarrito({contactoId, clienteId}) {
    yield put(actions.startLoading());
    try {
        const presupuesto = yield call(PresupuestosService.findCarrito, contactoId, clienteId);
        yield put(actions.receivePresupuestoDetalles(presupuesto));
        yield put(actionsProducts.receiveProductosAllSearchError(""))
    } catch (error) {
        yield put(actions.receivePresupuestoDetallesError(error));
        console.log(error)
    }
}

// retorna la cantidad de productos en el carrito
function* cantEnCarrito({contactoId, clienteId}) {
    yield put(actions.startLoading());
    try {
        const cantidad = yield call(PresupuestosService.getProductsInShoppingCart, contactoId, clienteId);
        yield put(actions.receiveCantInCarrito(cantidad.count));
    } catch (err) {
        yield put(actions.receiveCantInCarritoError(err));
        console.log(err)
    }
}

// busca presupuesto para confirmar el presupuesto
function* getCarrito({userId, clienteId}) {
    yield put(actions.startLoading());
    try {
        const presupuesto = yield call(PresupuestosService.getCarrito, userId, clienteId);
        yield put(actions.receiveGetPresupuestoDetalles(presupuesto));
    } catch (err) {
        yield put(actions.receiveGetPresupuestoDetallesError(err));
        console.log(err)
    }
}

//Busca datos, transportes y tiendas del clientes y las formas de pago disponibles para la preconfirmación del presupuesto
function* findPreconfirmData({presupuestoId}) {
    yield put(actions.startLoading());
    try {
        const preconfirmData = yield call(PresupuestosService.findPreconfirmData, presupuestoId);
        yield put(actions.receivePreconfirmData(preconfirmData));
    } catch (error) {
        yield put(actions.receivePreconfirmDataError({}));
        console.log(error)
    }
}

//Cancela el carrito
function* cancelPresupuesto({presupuestoId}) {
    yield put(actions.startLoading());
    try {
        const isCancel = yield call(PresupuestosService.cancelPresupuesto, presupuestoId);
        yield put(actions.receiveCancelarPresupuestoSucceeded(isCancel));
    } catch (err) {
        yield put(actions.receiveCancelarPresupuestoError(err));
        console.log(err)
    }
}

/* ABM DE PRESUPUESTOS */

function* addProduct({producto}) {
    yield put(actions.startLoading())
    try {
        const presupuesto = yield call(PresupuestosService.addProduct, producto);
        yield put(actions.receivePresupuestoAdd(presupuesto));
    } catch (err) {
        yield put(actions.receivePresupuestoAddError(err.response.data.message));
        console.log(err.response.data.message)
    }
}

function* deleteProduct({presupuestoId, item}) {
    yield put(actions.startLoading())
    try {
        const presupuesto = yield call(PresupuestosService.deleteProduct, presupuestoId, item);
        yield put(actions.receiveEliminarPresupuestoDetalle(presupuesto));
    } catch (err) {
        yield put(actions.receiveEliminarPresupuestoDetalleError(err));
        console.log(err)
    }
}

function* editProduct({cant, presupuestoId, item}) {
    yield put(actions.startLoading())
    try {
        const presupuesto = yield call(PresupuestosService.editProduct, cant, presupuestoId, item);
        yield put(actions.receiveEditarCantPresupuestoDetalle(presupuesto));
    } catch (err) {
        yield put(actions.receiveEditarCantPresupuestoDetalleError(err));
        console.log(err)
    }
}

function* editShoppingCart({presupuesto, presupuestoId}) {
    yield put(actions.startLoading())
    try {
        const updatedPresupuesto = yield call(PresupuestosService.editShoppingCart, presupuesto, presupuestoId);
        yield put(actions.receiveEditarPresupuesto(updatedPresupuesto));
    } catch (err) {
        yield put(actions.receiveEditarPresupuestoError(err));
        console.log(err)
    }
}

function*  watchGetPresupuestoConfirmar() {
    yield takeEvery(actions.Types.PRESUPUESTOSCONFIRMACION_FETCH_REQUESTED, PresupuestoConfirmar)
}

function* preconfirmPresupuesto({presupuesto}) {
    yield put(actions.startLoading())
    try {
        const success = yield call(PresupuestosService.preconfirmPresupuesto, presupuesto);
        yield put(actions.receivePreconfirmPresupuesto(success)); 
    } catch (err) {
        yield put(actions.receivePreconfirmPresupuestoError(err));
        console.log(err)
    }
}

function* requestDownloadFile(data) {
    yield put(actions.startLoading())
    try {
        const file = yield call(PresupuestosService.downloadFile, data);
        yield put(actions.receiveDownloadFile(file));
    } catch (err) {
        yield put(actions.receiveDownloadFileError(err));
    }
}

function*  watchRequestDownloadFile() {
    yield takeEvery(actions.Types.PRESUPUESTOS_DOWNLOAD_REQUESTED, requestDownloadFile)
}


function*  watchGetfetchPresupuestoConfirm() {
    yield takeEvery(actions.Types.PRESUPUESTOSCONFIRM_FETCH_REQUESTED, fetchPresupuestoConfirm)
}

function*  watchGetfetchPresupuestos() {
    yield takeEvery(actions.Types.PRESUPUESTOS_FETCH_REQUESTED, fetchPresupuestos)
}

function*  watchGetfetchClientes() {
    yield takeEvery(actions.Types.PRESUPUESTOS_CLIENTES_FETCH_REQUESTED, fetchClientes)
}

function*  watchGetfindPresupuesto() {
    yield takeEvery(actions.Types.PRESUPUESTOS_FIND_REQUESTED, findPresupuesto)
}

function*  watchGetsearchPresupuestos() {
    yield takeEvery(actions.Types.PRESUPUESTOS_SEARCH_REQUESTED, searchPresupuestos)
}

function*  watchGetfetchLatestPresupuestos() {
    yield takeEvery(actions.Types.PRESUPUESTOS_FETCH_LATEST_REQUESTED, fetchLatestPresupuestos)
}

function*  watchGetPresupuestoDetalles() {
    yield takeEvery(actions.Types.PRESUPUESTOS_DETALLES_REQUESTED, findCarrito)
}

function*  watchGetCarrito() {
    yield takeEvery(actions.Types.PRESUPUESTOS_GET_DETALLES_REQUESTED, getCarrito)
}

function*  watchGetPreconfirmData() {
    yield takeEvery(actions.Types.PRESUPUESTOS_PRECONFIRM_DATA_REQUESTED, findPreconfirmData)
}

function*  watchGetCancelPresupuesto() {
    yield takeEvery(actions.Types.PRESUPUESTOS_CANCELAR_REQUESTED, cancelPresupuesto)
}

function*  watchGetPresupuestoAddProduct() {
    yield takeEvery(actions.Types.PRESUPUESTOS_ADD_REQUESTED, addProduct)
}

function*  watchGetPresupuestoDeleteProduct() {
    yield takeEvery(actions.Types.PRESUPUESTOS_DELETE_REQUESTED, deleteProduct)
}

function*  watchGetPresupuestoEditProduct() {
    yield takeEvery(actions.Types.PRESUPUESTOS_EDIT_REQUESTED, editProduct)
}

function*  watchGetPresupuestoEditShoppingCart() {
    yield takeEvery(actions.Types.PRESUPUESTOS_EDITSHOPCART_REQUESTED, editShoppingCart)
}

function*  watchGetPreconfirmPresupuesto() {
    yield takeEvery(actions.Types.PRESUPUESTOS_PRECONFIRM_PRESUPUESTO_REQUESTED, preconfirmPresupuesto)
}

function*  watchGetCantInCarrito() {
    yield takeEvery(actions.Types.PRESUPUESTOS_CANT_CARRITO_REQUESTED, cantEnCarrito)
}

const presupuestoSagas = [
    fork(watchGetfetchPresupuestos),
    fork(watchGetfindPresupuesto),
    fork(watchGetsearchPresupuestos),
    fork(watchGetfetchLatestPresupuestos),
    fork(watchGetPresupuestoDetalles),
    fork(watchGetPreconfirmData),
    fork(watchGetPresupuestoAddProduct),
    fork(watchGetPresupuestoDeleteProduct),
    fork(watchGetPresupuestoEditProduct),
    fork(watchGetCancelPresupuesto),
    fork(watchGetPresupuestoEditShoppingCart),
    fork(watchGetfetchClientes),
    fork(watchGetfetchPresupuestoConfirm),
    fork(watchGetPresupuestoConfirmar),
    fork(watchGetPreconfirmPresupuesto),
    fork(watchRequestDownloadFile),
    fork(watchGetCarrito),
    fork(watchGetCantInCarrito)
]

export default presupuestoSagas;
